<template>
    <div class="page-rooter-fff">
        <headBar ref="header" title="赠礼记录" left-arrow @click-left="newAppBack" />
        <van-pull-refresh v-model="refreshing" @refresh="getDataList">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多记录了~"
                @load="getDataList"
                :immediate-check="false"
                class="list"
                v-if="list.length"
            >

                <div v-for="(item, index) in list" :key="index">
                    <div v-if="item.status === 'IN_GIVING'" class="tips">预计于<span>{{ item.receiveDeadlineTime }}</span>过期，超时未领取将被退回</div>
                    <div class="item-box" :class="{marginTop: item.status === 'IN_GIVING'}">
                        <div class="item row-between-center">
                            <img :src="item.redPacketBackgroundImgUrl" alt="">
                            <div>
                                <div class="name row-between-center">
                                    <div>{{ item.givingWords }}</div>
                                    <p>已领<span>{{ item.hasReceiveNum || 0 }}/{{ item.givingNum || 0 }}</span></p>
                                </div>
                                <div class="date">发出时间：<span>{{ item.givingTime }}</span></div>
                                <p v-if="item.status === 'IN_GIVING'">红包派送中…</p>
                                <p v-if="item.status === 'CANCEL'" style="color: #6C727A">红包已撤回，未被领取的卡券已退回您账户</p>
                                <p v-if="item.status === 'ALL_RECEIVED'" style="color: #6C727A">红包已被全部领取</p>
                                <p v-if="item.status === 'EXPIRED'" style="color: #6C727A">红包已过期，未被领取的卡券已退回您账户</p>
                            </div>
                        </div>
                        <div class="btn row-end-center">
                            <p v-if="item.status === 'IN_GIVING'" @click="confirmCancel(item)" class="row-center-center">撤回赠送</p>
                            <p @click="jumpMini(item)" class="row-center-center">赠送明细</p>
                            <p v-if="item.status === 'IN_GIVING'" @click="shareMini(item)" class="row-center-center active">再次分享</p>
                        </div>
                    </div>
                </div>
            </van-list>
            <div v-else class="empty col-center-center">
                <img src="https://img.chaolu.com.cn/ACT/give-equity-card/empty.png" alt="">
                <p>暂无赠送记录</p>
            </div>
        </van-pull-refresh>

        <!-- 微信、小程序右上角分享提示 -->
        <van-overlay :show="isShareWx" :z-index="1500" @click="closeShareBox">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2021/share-mini.png" class="tip-img"/>
            </div>
        </van-overlay>


        <van-popup v-model="show" :style="{backgroundColor: 'transparent'}">
            <div class="popup-box">
                <div class="popup-title">撤回赠送</div>
                <div class="popup-content">撤回赠送则立即停止红包派发，未被领取的卡券将原路退回到您的账户</div>
                <div class="popup-btn row-start-start">
                    <div @click="show = false">我再想想</div>
                    <div @click="cancel()">确认撤回</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {initBack, newAppBack, webAppShare} from '@/lib/appMethod';
import userMixin from '@/mixin/userMixin';
import headBar from "@/components/app/headBar";
import {getParam} from "@/lib/utils";
import wx from "weixin-js-sdk";
export default {
    components: {
        headBar,
    },
    mixins: [userMixin],
    data() {
        return {
            show: false,
            loading: false,
            finished: false,
            refreshing: false,
            isShareWx: false,
            list: [],
            id: '',
            shareParams: {
                title: '『超鹿运动』按次付费的健身房！快来约课吧',
                miniImage: '',
                path: '/pages/home/index',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1
                    ? '0'
                    : '2', // 正式0，开发1，体验2
                url: 'url',
                multiple: "0.95",
                userName: "gh_7f5b57b6a76e",
            },
        }
    },
    async created() {
        initBack();
        await this.$getUserId();
        this.getDataList(true)
        if(this.appTypeStr === 'mini'){
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: '',
                    shareTitle: '『超鹿运动』按次付费的健身房！快来约课吧',
                    shareUrl: '/pages/home/index',
                }
            });
        }
    },
    mounted() {
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                if (this.userId && this.token) {
                    this.getDataList(true)
                }
            }
        })
    },
    methods: {
        newAppBack,
        getDataList(noRefreshing) {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.loading = true
            this.refreshing = !noRefreshing
            this.$axios.post(`${this.baseURLApp}/user/giving/record/list`, {
                token: this.token,
                userId: this.userId
            }).then(res => {
                this.list = res.data
                this.loading = false
                this.refreshing = false
                this.finished = true
                this.$toast.clear()
            }).catch(r => {
                this.$toast.clear()
            })
        },
        shareMini(item){
            if(this.appTypeStr === 'mini'){
                // this.isShareWx = true
                // wx.miniProgram.postMessage({
                //     data: {
                //         type: 'share',
                //         shareImage: item.redPacketCoverImgUrl,
                //         shareTitle: item.givingWords,
                //         shareUrl: `/pages/activity/receivingGift/index?shareId=${item.id}`,
                //     }
                // });
                wx.miniProgram.navigateTo({
                    url: `/pages/activity/receivingGiftShare/index?shareId=${item.id}&scenePage=1`
                })
            }else{
                let miniParams = JSON.parse(JSON.stringify(this.shareParams))
                miniParams.image = item.redPacketCoverImgUrl
                miniParams.title = item.givingWords
                miniParams.path = `/pages/activity/receivingGift/index?shareId=${item.id}`
                webAppShare('3', miniParams)
            }
        },
        confirmCancel(v){
            this.id = v.id
            this.show = true
        },
        cancel() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.show = false
            this.$axios.post(`${this.baseURLApp}/user/giving/record/cancel`, {
                token: this.token,
                userId: this.userId,
                givingRecordId: this.id
            }).then(res => {
                this.id = ''
                this.$toast.clear()
                this.$toast('撤销赠送成功')
                this.getDataList(true)
            }).catch(r => {
                this.$toast.clear()
            })
        },
        closeShareBox(){
            this.isShareWx = false
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/mini-share-202108.png',
                    shareTitle: '『超鹿运动』按次付费的健身房！快来约课吧',
                    shareUrl: '/pages/home/index',
                }
            });
        },
        jumpMini(v) {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/activity/receivingGift/index?shareId=${v.id}&isSelf=1`,
                })
            }
            if (this.appTypeStr === 'ios') {
                window.webkit.messageHandlers.pushMiniProgram.postMessage({ miniProgramType: '0', userName: 'gh_7f5b57b6a76e', path: `/pages/activity/receivingGift/index?shareId=${v.id}&isSelf=1` })
            }
            if (this.appTypeStr === 'and') {
                App.web2native('31', JSON.stringify({ userName: 'gh_7f5b57b6a76e', path: `/pages/activity/receivingGift/index?shareId=${v.id}&isSelf=1` }))
            }
        }
    }
}
</script>

<style scoped lang="less">
.page-rooter-fff{
    background: #F5F5F5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    box-sizing: border-box;
    overflow: hidden;
    .tips{
        background-color: #FFF5ED;
        padding: 20px 0 30px;
        border-radius: 16px 16px 0 0;
        text-align: center;
        font-size: 22px;
        color: #242831;
        span{
            color: #F03C18;
        }
    }
    .list{
        padding: 0 32px;
        .item-box{
            &.marginTop{
                margin-top: -10px;
            }
            padding: 24px 32px;
            background-color: white;
            margin-bottom: 24px;
            border-radius: 16px;
            .item{
                padding-bottom: 24px;
                border-bottom: 1px dashed #DDDDDD;
                img{
                    width: 88px;
                    height: 120px;
                    border-radius: 6px;
                }
                &>div{
                    flex-grow: 1;
                    padding-left: 32px;
                    .name{
                        div{
                            font-size: 26px;
                            line-height: 28px;
                            font-weight: bold;
                            color: #242831;
                        }
                        p{
                            line-height: 24px;
                            font-size: 24px;
                            color: #6C727A;
                            margin-top: 0;
                            span{
                                font-weight: bold;
                                color: #F03C18;
                                padding-left: 8px;
                            }
                        }
                    }
                    .date{
                        line-height: 20px;
                        font-size: 20px;
                        color: #242831;
                        margin-top: 22px;
                        span{
                            color: #6C727A;
                        }
                    }
                    p{
                        margin-top: 22px;
                        line-height: 20px;
                        font-size: 20px;
                        color: #FF6E00;
                    }
                }
            }
            .btn{
                padding-top: 24px;
                p{
                    width: 136px;
                    height: 56px;
                    border-radius: 4px;
                    opacity: 1;
                    border: 1px solid #6C727A;
                    box-sizing: border-box;
                    color: #242831;
                    font-size: 24px;
                    margin-left: 24px;
                    &.active{
                        border-color: #FFDE00;
                        background: #FFDE00;
                    }
                }
            }
        }
    }
    .empty{
        flex-grow: 1;
        img{
            width: 300px;
            height: 300px;
        }
        p{
            font-size: 28px;
            color: #6C727A;
        }
    }
}
.tip-box {
    position: relative;
    .tip-img {
        position: absolute;
        top: 7px;
        right: 130px;
        width: 300px;
        z-index: 99999;
        display: block;
    }
}
.popup-box{
    width: 630px;
    background: #FFFFFF;
    border-radius: 12px;
    .popup-title{
        padding: 82px 0 32px;
        text-align: center;
        font-weight: bold;
        font-size: 36px;
        line-height: 36px;
        color: #242831;
    }
    .popup-content{
        padding: 0 60px;
        text-align: center;
        font-size: 28px;
        line-height: 44px;
        color: #9AA1A9;
    }
    .popup-btn{
        border-top: 1px solid #EAEAEA;
        margin-top: 80px;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 108px;
            width: 50%;
            font-size: 32px;
            color: #9AA1A9;
            &:last-child{
                border-left: 1px solid #EAEAEA;
                color: #FF6E00;
            }
        }
    }
}
</style>
